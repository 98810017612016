import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Mulish\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--mulish-font\",\"display\":\"swap\"}],\"variableName\":\"mulish\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/app/speculation-rules.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/app/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/loading-page/loading-page.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/navigation-events.ts");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/popup/cookies-popup.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/modules/_app/initial-render.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/modules/_app/scripts/appsflyer.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/modules/_app/ToastManager.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/modules/_app/update-history.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/styles/global.scss");
